import BaseBlockContent from "@sanity/block-content-to-react";
import React, { FC } from "react";
import serializers from "../serializers";
import style from "./block-content.module.scss";

interface IBlockText {
  blocks: any;
  overwriteStyles?: any;
}

const BlockText: FC<IBlockText> = ({ blocks, overwriteStyles }: IBlockText) => (
  <BaseBlockContent
    className={`${style.blockContent} ${overwriteStyles}`}
    blocks={blocks}
    serializers={serializers}
    renderContainerOnSingleChild
  />
);

export default BlockText;
