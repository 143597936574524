import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import { pages, PageContext } from "../context/page-context";
import favicon from "../images/favicon.ico";

interface queryProps {
  site: {
    keywords: Array<string>;
    contact: {
      description: string;
      title: string;
    };
    formSubmitted: {
      description: string;
      title: string;
    };
    homepage: {
      description: string;
      title: string;
    };
    pageNotFound: {
      description: string;
      title: string;
    };
    privacy: {
      description: string;
      title: string;
    };
    product: {
      description: string;
      title: string;
    };
    technical: {
      description: string;
      title: string;
    };
  };
}

const CustomHelmet: React.FC = () => {
  const { site } = useStaticQuery<queryProps>(detailsQuery);
  const { product, technical, contact, homepage, pageNotFound, formSubmitted, privacy } = site;
  const pageContext = useContext(PageContext);
  let pageTitle: string;
  let metaDescription: string;
  switch (pageContext.currentPage) {
    case pages.product:
      pageTitle = product.title;
      metaDescription = product.description;
      break;
    case pages.technical:
      pageTitle = technical.title;
      metaDescription = technical.description;
      break;

    case pages.contact:
      pageTitle = contact.title;
      metaDescription = contact.description;
      break;

    case pages.notFound:
      pageTitle = pageNotFound.title;
      metaDescription = pageNotFound.description;
      break;

    case pages.formSubmitted:
      pageTitle = formSubmitted.title;
      metaDescription = formSubmitted.description;
      break;

    case pages.privacy:
      pageTitle = privacy.title;
      metaDescription = privacy.description;
      break;

    default:
      pageTitle = homepage.title;
      metaDescription = homepage.description;
  }
  let URL = `https://www.cloudgin.co.uk/${pageContext.currentPage}`;
  const imageURL =
    "https://cdn.sanity.io/images/q2yc0rib/production/9cc8e5291f9a09be499fccd2b8aea5b1f3253c7c-1000x667.jpg?h=550&fit=crop";

  let keywordsString = (site && site.keywords.join(", ")) || "CBD, Gin, Cloud Gin";
  return (
    <Helmet defaultTitle="Cloud Gin" titleTemplate="Cloud Gin | %s">
      <html lang="en" />
      <title>{pageTitle}</title>

      <meta name="description" content={metaDescription} />

      {/* facebook stuff */}

      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:image" content={imageURL} />
      <meta property="og:url" content={URL} />
      <meta property="og:site_name" content="Cloud Gin"></meta>

      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={imageURL} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:image:alt"
        content="Cloud Gin filled bottle with white background."
      ></meta>

      <meta name="keywords" content={keywordsString} />

      <meta name="robots" content="index" />

      <link rel="icon" href={favicon} />
      <link
        href="https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap"
        rel="stylesheet"
      />
      {/*<script>*/}
      {/*  {`(function(w,d,s,r,n){w.TrustpilotObject=n;w[n]=w[n]||function(){(w[n].q=w[n].q||[]).push(arguments)};*/}
      {/*      a=d.createElement(s);a.async=1;a.src=r;a.type='text/java'+s;f=d.getElementsByTagName(s)[0];*/}
      {/*      f.parentNode.insertBefore(a,f)})(window,document,'script', 'https://invitejs.trustpilot.com/tp.min.js', 'tp');*/}
      {/*      tp('register', 'JsSlM0u5qco3Kmwc');`}*/}
      {/*</script>*/}
      {/*TrustBox script */}
      <script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      ></script>
      {/*End TrustBox script */}
    </Helmet>
  );
};

export default CustomHelmet;

const detailsQuery = graphql`
  query SEOQuery {
    site: sanitySiteSettings(_id: { eq: "siteSettings" }) {
      keywords
      contact {
        description
        title
      }
      formSubmitted {
        description
        title
      }
      homepage {
        description
        title
      }
      pageNotFound {
        description
        title
      }
      privacy {
        description
        title
      }
      product {
        description
        title
      }
      technical {
        description
        title
      }
    }
  }
`;
