import React, { FC } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { SiInstagram, SiLinkedin } from "react-icons/si";
import { ImFacebook2 } from "react-icons/im";
import BlockText from "../content/block-text";
import logo from "../../images/svg/logo.svg";
import TrustBox from "../trustbox";
import style from "./footer.module.scss";
import IconButton from "@material-ui/core/IconButton";

interface IQuery {
  sanityFooter: {
    id: string;
    _rawContactInformation: any;
    _rawDeliveryInformation: any;
  };
}

const Footer: FC = () => {
  const data = useStaticQuery<IQuery>(footerQuery);
  return (
    <footer className={style.footer}>
      <div className={`container ${style.footerContent}`}>
        <div className={style.logo}>
          <img src={logo} alt="cloud gin logo" />
          <h2>Cloud Gin</h2>
          <p>#ExpertsInUnwinding</p>
          <div className={style.socialLinks}>
            <IconButton>
              <a className={style.instagram} href="https://www.instagram.com/cloud_gin/">
                <SiInstagram fontSize="4rem" />
              </a>
            </IconButton>
            <IconButton>
              <a className={style.facebook} href="https://www.facebook.com/ExpertsInUnwinding/">
                <ImFacebook2 fontSize="4rem" />
              </a>
            </IconButton>
            <IconButton>
              <a className={style.linkedin} href="https://www.linkedin.com/company/cloud-gin">
                <SiLinkedin fontSize="4rem" />
              </a>
            </IconButton>
          </div>
          <TrustBox id="56278e9abfbbba0bdcd568bc" height="52px" />
        </div>
        <section className={style.footerBody}>
          <div className={style.footerLinks}>
            <h4>Explore</h4>
            <div className={style.linkGroup}>
              <Link className={style.footerLink} to="/">
                Home
              </Link>
              <Link className={style.footerLink} to="/product">
                Our Product
              </Link>
              <Link className={style.footerLink} to="/technical">
                What is Cloud Gin?
              </Link>
              <Link className={style.footerLink} to="/privacy-information">
                Privacy Information
              </Link>
            </div>
          </div>
          <div className={style.deliveryInfo}>
            <h4>Delivery Information</h4>
            <BlockText
              overwriteStyles={style.blockText}
              blocks={data.sanityFooter._rawDeliveryInformation}
            />
          </div>
          <div className={style.companyInfo}>
            <h4>Contact Information</h4>
            <Link to="/contact" className={style.contactLink}>
              Use Our Contact Form
            </Link>
            <BlockText
              overwriteStyles={style.blockText}
              blocks={data.sanityFooter._rawContactInformation}
            />
          </div>
        </section>
      </div>
    </footer>
  );
};

export default Footer;

export const footerQuery = graphql`
  query footerQuery {
    sanityFooter {
      id
      _rawContactInformation
      _rawDeliveryInformation
    }
  }
`;
