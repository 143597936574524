import React, { useState, useContext } from "react";
import { Link } from "gatsby";

import Navbar from "../navbar/navbar";
import Footer from "./footer";
import Helmet from "../custom-helmet";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import Slider from "@material-ui/core/Slider";

import { PageContext, pages } from "../../context/page-context";

import Button from "../button/button";

import "./layout.scss";
import style from "./page-layout.module.scss";

const AgeSlider = withStyles({
  root: {
    color: "#0082a4",
    height: 8,
    paddingTop: "70px",
    maxWidth: "90%",
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
    fontSize: "1.8rem",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#808080",
    color: "#fff",
    boxShadow: theme.shadows[1],
    fontSize: "1.6rem",
  },
  tooltipPlacementTop: {
    margin: "10px 0",
  },
  arrow: {
    color: "#808080",
  },
}))(Tooltip);

// Custom Value Label
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <CustomTooltip arrow open={open} enterTouchDelay={0} placement="top" title={`${value} Years`}>
      {children}
    </CustomTooltip>
  );
}

// Custom Dialog Styles
const AgeDialog = withStyles((theme) => ({
  paper: {
    width: "450px",
    padding: "40px",
    backgroundColor: "#F2F5F7",
  },
}))(Dialog);

// if (typeof window !== `undefined`) {
//   document.addEventListener("snipcart.ready", () => {
//     // You can safely use window.Snipcart here
//     // @ts-ignore
//     Snipcart.events.on("item.added", (cartItem) => {
//       console.log(cartItem);
//     });
//     // @ts-ignore
//     Snipcart.events.on("cart.confirmed", (cartConfirmResponse) => {
//       console.log(cartConfirmResponse);
//
//       const trustpilot_invitation = {
//         recipientEmail: cartConfirmResponse.email,
//         recipientName: cartConfirmResponse.shippingAddress.fullName,
//         referenceId: cartConfirmResponse.invoiceNumber,
//         source: "InvitationScript",
//         productSkus: ["4a439bff-7078-502e-a724-fd2f4dc3f8cf"],
//         locale: "en-GB",
//         products: [
//           {
//             sku: "4a439bff-7078-502e-a724-fd2f4dc3f8cf",
//             productUrl: "https://www.cloudgin.co.uk/product",
//             name: "Cloud_Gin",
//             imageUrl:
//               "https://cdn.sanity.io/images/q2yc0rib/production/27a75946dc64829f83d514ac7dd8ae27190d5588-634x951.jpg?fit=crop",
//           },
//         ],
//       };
//       // @ts-ignore
//       tp("createInvitation", trustpilot_invitation);
//     });
//   });
// }

interface IProps {
  children: any;
  sticky?: boolean;
  privacyPage?: boolean;
}

// actual page below =================================================================
const PageLayout: React.FC<IProps> = ({ children, sticky }: IProps) => {
  const [open, setOpen] = useState(true);
  const [age, setAge] = useState(18);
  const [showAlert, setShowAlert] = useState(false);

  const pageContext = useContext(PageContext);

  let privacyPage = pageContext.currentPage === pages.privacy;

  let ageVerification =
    typeof window !== `undefined` && window.localStorage.getItem("ageVerification") === "true";

  const handleClose = () => {
    if (age >= 18) {
      setOpen(false);
      typeof window !== `undefined` && window.localStorage.setItem("ageVerification", "true");
    } else {
      setShowAlert(true);
    }
  };

  const handleChange = (_evt: any, value: number) => {
    setAge(value);
  };
  return (
    // Page provider stores the current page state information for all components to use
    <>
      <Helmet />

      <Navbar sticky={sticky} />

      <AgeDialog
        open={!privacyPage && !ageVerification && open}
        aria-labelledby="age-verification"
        aria-describedby="age-verification-window"
      >
        <div className={style.dialogInner}>
          <h1 className={style.dialogTitle}>Cloud Gin</h1>
          <h1 className={style.tagLine}>Mastercrafted CBD Gin</h1>

          <AgeSlider
            min={0}
            max={100}
            ValueLabelComponent={ValueLabelComponent}
            valueLabelDisplay="on"
            aria-label="age slider"
            defaultValue={18}
            // @ts-ignore
            onChange={handleChange}
          />
          <p className={style.enterText}>Please enter your age to continue.</p>
          <p className={`${style.alertText} ${showAlert ? style.showAlert : ""}`}>
            You must be over the legal drinking age to enter this site.
          </p>
          <Button className={style.enterBtn} type="outline" onClick={handleClose}>
            Enter
          </Button>
          <p className={style.privacyWarning}>
            By entering this site you accept the use of cookies to enhance the user experience and
            collect usage statistics. <Link to="/privacy-information">Find out More</Link>
          </p>
        </div>
      </AgeDialog>

      {children}
      <Footer />
    </>
  );
};

export default PageLayout;
