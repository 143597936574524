import React, { useState, FC } from "react";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { navigate } from "gatsby";

// import styles ========================================
import style from "./left-nav-mobile.module.scss";

import { IAllSanityArticle, IAllSanityCategory } from "../navbar-query-type";

interface IMobile {
  allSanityArticle: IAllSanityArticle;
  allSanityCategory: IAllSanityCategory;
  setDrawerOpen: (arg: boolean) => void;
}

const LeftNavMobile: FC<IMobile> = ({
  allSanityArticle,
  allSanityCategory,
  setDrawerOpen,
}: IMobile) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState<string>("none");

  const articles = allSanityArticle.edges;
  const categories = allSanityCategory.edges;

  const handleNavigate = (url: string) => {
    setDrawerOpen(false);
    navigate(url);
  };
  const handleMainMenuToggle = (): void => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleSecondaryMenuToggle = (menu: string): void => {
    if (activeMenu === menu) {
      setActiveMenu("none");
    } else {
      setActiveMenu(menu);
    }
  };
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={style.list}
      subheader={
        <ListSubheader className={style.navHeader} component="div" id="nested-list-subheader">
          Cloud Gin
        </ListSubheader>
      }
    >
      <ListItem className={style.listItem} button onClick={() => handleNavigate("/")}>
        <li>Home</li> {/* change this line for the labels */}
      </ListItem>
      <Divider />
      <ListItem className={style.listItem} button onClick={() => handleNavigate("/product")}>
        <li>The Gin</li> {/* change this line for the labels */}
      </ListItem>
      <Divider />
      <ListItem className={style.listItem} button onClick={() => handleNavigate("/technical")}>
        <li>What is Cloud Gin?</li> {/* change this line for the labels */}
      </ListItem>
      <Divider />
      <ListItem className={style.listItem} button onClick={() => handleNavigate("/contact")}>
        <li>Contact Us</li> {/* change this line for the labels */}
      </ListItem>
      <Divider />

      <ListItem
        className={`${style.blogListItem} ${style.listItem}`}
        button
        onClick={handleMainMenuToggle}
      >
        <li>Articles</li>
        {dropdownOpen ? (
          <ExpandLess className={style.expand} />
        ) : (
          <ExpandMore className={style.expand} />
        )}
      </ListItem>

      <Collapse in={dropdownOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {categories.map((category) => (
            <div key={category.node._id}>
              <ListItem
                onClick={() => handleSecondaryMenuToggle(category.node.title)}
                button
                className={style.mainDropdownItem}
              >
                <li>{category.node.title}</li>
                {activeMenu === category.node.title ? (
                  <ExpandLess className={style.expand} />
                ) : (
                  <ExpandMore className={style.expand} />
                )}
              </ListItem>
              <Collapse in={activeMenu === category.node.title} timeout="auto" unmountOnExit>
                {articles
                  .filter((article) =>
                    article.node.categories.some((element) => element.title === category.node.title)
                  )
                  .map((article) => (
                    <ListItem
                      key={article.node._id}
                      className={style.secondaryDropdownItem}
                      button
                      onClick={() => handleNavigate(`/${article.node.slug.current}`)}
                    >
                      <li>{article.node.shortTitle}</li>
                    </ListItem>
                  ))}
              </Collapse>
              <Divider className={style.dropdownDivider} />
            </div>
          ))}
          {/*<ListItem*/}
          {/*  onClick={() => handleNavigate("/articles")}*/}
          {/*  button*/}
          {/*  className={style.mainDropdownItem}*/}
          {/*>*/}
          {/*  <li>See All Articles</li>*/}
          {/*</ListItem>*/}
        </List>
      </Collapse>
      <Divider />
    </List>
  );
};

export default LeftNavMobile;
