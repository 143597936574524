import React, { useState, FC } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

// import Material ui =================================================
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { AiFillCloseCircle } from "react-icons/ai";

// import components =================================================
import LeftNavMobile from "./Mobile/left-nav-mobile";
import LeftNavDesktop from "./desktop/left-nav-desktop";
import TrustBox from "../trustbox";

// import styles =====================================================
import style from "./navbar.module.scss";

import { IAllSanityArticle, IAllSanityCategory } from "./navbar-query-type";
interface INavbar {
  sticky?: boolean;
}

const Navbar: FC<INavbar> = ({ sticky = true }) => {
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const {
    allSanityArticle,
    allSanityCategory,
  }: {
    allSanityArticle: IAllSanityArticle;
    allSanityCategory: IAllSanityCategory;
  } = useStaticQuery(navbarQuery);

  return (
    <header className={sticky ? style.header : ""}>
      {/* trust pilot thing */}
      {sticky && (
        <div className={style.banner}>
          <TrustBox id="5419b6a8b0d04a076446a9ad" theme="light" />
        </div>
      )}
      {/* trust pilot thing */}
      <nav className={`${sticky ? style.sticky : null}  ${style.navbar}`}>
        <div className={style.mobileMenu}>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            <MenuIcon
              fontSize="large"
              style={{ fill: sticky ? "rgb(42, 42, 42)" : "white", marginLeft: "1rem" }}
            />
          </IconButton>
        </div>

        <Link className={`${style.logo} ${style.link}`} to="/">
          {/* <img onLoad={SVGInject(this)} src={logo} alt="Cloud gin logo" /> */}
          <svg
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 140 100"
            xmlns="http://www.w3.org/2000/svg"
            height="35px"
          >
            <g fill={sticky ? "#2a2a2a" : "#fff"}>
              <path d="M103.06 39.98h9.84a28.17 28.17 0 0110.42 1.73 14.8 14.8 0 017.23 6.75 21.47 21.47 0 012.9 10.91 19.95 19.95 0 01-3.19 11.13 16.56 16.56 0 01-7.34 6.62 26.45 26.45 0 01-10.13 1.73H94.55v-1.48h6.06V68.8a19.58 19.58 0 003.18-11.18 18.94 18.94 0 00-3.18-10.6l.02-.05a29 29 0 002.43-6.99zm4.45 1.48v35.9h5.04a23.9 23.9 0 006.48-.72 7.42 7.42 0 004.14-3.15 15.09 15.09 0 002.22-6.18 66.2 66.2 0 00.58-9.76q0-7.25-1.84-10.81t-4.5-4.42a23.32 23.32 0 00-6.96-.86z" />
              <path d="M23.53 77.37h71.03v1.48H23.53z" />
              <path
                d="M30.21 41.66a13.88 13.88 0 01.15-4.59 8.63 8.63 0 01.7-2.03 6.32 6.32 0 011.2-1.64 6.64 6.64 0 012-1.41 10.39 10.39 0 011.67-.6 17.26 17.26 0 014.62-.53.27.27 0 00.06-.53 19.1 19.1 0 00-3.52-.44 11.5 11.5 0 00-2.44.19 7.98 7.98 0 00-2.78 1.07 5.95 5.95 0 00-1.04.83 6.18 6.18 0 00-1.07 1.42 7.45 7.45 0 00-.82 2.44 10.54 10.54 0 000 3.08 12.86 12.86 0 00.76 2.9.27.27 0 10.51-.16z"
                fillRule="evenodd"
              />
              <path
                d="M38.79 30s-2.97-7.4.48-14.71c1.68-3.59 4.96-7.1 10.86-9.72a22.25 22.25 0 0114.8-1.45 22.71 22.71 0 0112.1 8.47 31.46 31.46 0 012.66 4.73 25.73 25.73 0 011.88 5.4 16.57 16.57 0 01-1.15 11.08l-.8 1.41 2.83 1.58.79-1.41a19.89 19.89 0 002.87-13.43 24.7 24.7 0 00-1.85-6.67 24.57 24.57 0 00-3.76-6.16A26.56 26.56 0 0065.77.4C61.1-.48 55.45-.1 49.04 3.27c-6.32 3.23-9.65 7.31-11.31 11.34-3.3 8 .14 15.78.14 15.78l.19.46.92-.4-.2-.45z"
                fillRule="evenodd"
              />
              <path
                d="M82.03 34.7a24.36 24.36 0 013.05-3.63 15.52 15.52 0 015.75-3.74 8.27 8.27 0 016.97.7 9.2 9.2 0 012.55 1.94 9.05 9.05 0 012.16 4.28 16.31 16.31 0 01.2 5.11 29.35 29.35 0 01-1.95 7.68l.43.22a28.6 28.6 0 002.81-7.7 16.82 16.82 0 00.23-5.62 10.37 10.37 0 00-2.34-5.34 10.7 10.7 0 00-3.1-2.43 10.55 10.55 0 00-4.24-1.2 9.74 9.74 0 00-4.32.77 15.12 15.12 0 00-5.08 3.71 23.62 23.62 0 00-3.53 5.02z"
                fillRule="evenodd"
              />
              <path
                d="M76.21 34.06a42.99 42.99 0 0114.37 9.39 22.1 22.1 0 015.05 8.08 18.44 18.44 0 011.1 6.1 17.5 17.5 0 01-3.86 12.7 24.45 24.45 0 01-12.18 7.49.3.3 0 00.07.58s8.23-.35 14.4-5.88a19.17 19.17 0 005.38-8.34 24.06 24.06 0 001.1-6.56A19.8 19.8 0 0096 43.2a28.52 28.52 0 00-7.24-5.46 41.24 41.24 0 00-12.4-4.23.3.3 0 00-.13.57zM74.82 44.92v11.8a.35.35 0 00.71 0v-11.8a.35.35 0 00-.7 0zM42.55 78.24s-.59-3.8.06-4.7a.5.5 0 01.46-.2c.44.06.4 1.38 1.36 2.7a10.96 10.96 0 002.42 2.24z"
                fillRule="evenodd"
              />
              <path d="M40.47 40.43v11.02h-1.78Q35.8 41.4 23.73 41.4q-6.5 0-9.47 3.4T11.3 59.16q0 9 1.28 12.25A8.17 8.17 0 0017 76.04 17.12 17.12 0 0024 77.37a18.25 18.25 0 009.3-2.44 16.38 16.38 0 006.76-8.05H42l-.66 11.33h-1.7a7.85 7.85 0 00-2.97-3 27.33 27.33 0 01-13.44 3.64 25.62 25.62 0 01-16-5.57Q0 67.7 0 59.65q0-8.22 7.11-13.94a25.49 25.49 0 0116.45-5.72 23.3 23.3 0 0112.7 3.46 21.33 21.33 0 002.43-3.02z" />
              <path
                d="M39.37 32.17c1.88 1.24 7.23 7.65 7.23 7.65a12.03 12.03 0 01-9.65-3.43c-1.7-1.71-2.28-3.29-1.65-4a3.76 3.76 0 014.07-.22zM54.62 4.82s11.24 5.45 20.2 27.77l4.64.88s2-3 1.2-8.37A25.09 25.09 0 0068.55 6.9a20.72 20.72 0 00-13.93-2.07zM77 56.67a5.62 5.62 0 002.16.43 6.3 6.3 0 000-12.57 5.62 5.62 0 00-2.16.43zM123.93 47.44a27.26 27.26 0 011.43 7.64c.5 7.06.2 15.16-4.23 19.73-2.97 3.08-13.02 1.98-13.02 1.98 13.34-4.07 16.33-14.36 15.82-29.35zM91.3 27.65s11.82-1.94 10.85 12.35c-1.38-6.15-4.28-11.18-10.85-12.35zM20.55 42.56s18.46-3.77 18.26 18.58c-2.11-9.1-8.1-15.36-18.26-18.58z"
                fillRule="evenodd"
              />
            </g>
          </svg>
          Cloud Gin
        </Link>

        <LeftNavDesktop allSanityArticle={allSanityArticle} allSanityCategory={allSanityCategory} />

        <a className={`shopping-icon snipcart-checkout ${style.rightMenu}`}>
          <p>Basket</p>
          <ShoppingCartIcon style={{ fontSize: "2rem" }} />
        </a>
        <Drawer
          classes={{ paper: style.paper }}
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <IconButton onClick={() => setDrawerOpen(false)} className={style.closeDrawer}>
            <AiFillCloseCircle size="27px" />
          </IconButton>
          <LeftNavMobile
            allSanityArticle={allSanityArticle}
            allSanityCategory={allSanityCategory}
            setDrawerOpen={setDrawerOpen}
          />
        </Drawer>
      </nav>
    </header>
  );
};

export default Navbar;

export const navbarQuery = graphql`
  query BlogDropdown {
    allSanityArticle {
      edges {
        node {
          _id
          categories {
            title
          }
          title
          shortTitle
          slug {
            current
          }
        }
      }
    }
    allSanityCategory {
      edges {
        node {
          _id
          title
        }
      }
    }
  }
`;
