import React, { FC } from "react";

import { navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

import style from "./button.module.scss";
import { IconType } from "react-icons";

interface IButton {
  linkTo?: string;
  onClick?: () => void;
  rightIcon?: any;
  className?: string;
  type: "outline" | "filled";

  children: any;
}

const Button: FC<IButton> = ({
  linkTo,
  onClick,
  rightIcon,
  children,
  className,
  type = "outline",
}: IButton) => {
  const handleNavigate = () => {
    navigate(linkTo);
  };
  return (
    <ButtonBase
      className={`${type === "outline" ? style.outline : style.filled} ${className}`}
      onClick={linkTo  ? handleNavigate : onClick}
    >
      {children}
      {rightIcon}
    </ButtonBase>
  );
};

export default Button;
