import React, { useState, FC } from "react";
import { CSSTransition } from "react-transition-group";

import DropdownItem from "../dropdown-item/dropdown-item";
import DropdownLink from "../dropdown-item/dropdown-link";

import { FaCaretRight, FaCaretLeft } from "react-icons/fa";

import "../menu-animation.scss";
import style from "./blog-dropdown-menu.module.scss";
import { IAllSanityArticle, IAllSanityCategory } from "../../navbar-query-type";

interface IDropDownMenu {
  allSanityArticle: IAllSanityArticle;
  allSanityCategory: IAllSanityCategory;
}

const DropdownMenu: FC<IDropDownMenu> = ({
  allSanityArticle,
  allSanityCategory,
}: IDropDownMenu) => {
  const [activeMenu, setActiveMenu] = useState<string>("main");
  const [menuHeight, setMenuHeight] = useState();

  const articles = allSanityArticle.edges;
  const categories = allSanityCategory.edges;

  function calcHeight(el) {
    const height = el.offsetHeight;
    setMenuHeight(height + 30);
  }

  return (
    <div className={style.dropdown} style={{ height: menuHeight }}>
      {/* main menus */}
      <CSSTransition
        in={activeMenu === "main"}
        unmountOnExit
        timeout={500}
        classNames="menu-primary"
        onEnter={calcHeight}
      >
        <div>
          {categories.map((category) => (
            <DropdownItem
              key={category.node._id}
              setActiveMenu={setActiveMenu}
              goToMenu={category.node.title}
              rightIcon={<FaCaretRight />}
            >
              {category.node.title}
            </DropdownItem>
          ))}
          {/* <DropdownLink linkTo="/articles">See All</DropdownLink> */}
        </div>
      </CSSTransition>

      {/* below is the secondary menus */}
      {categories.map((category) => (
        <CSSTransition
          key={category.node._id}
          in={activeMenu === category.node.title}
          unmountOnExit
          timeout={500}
          classNames="menu-secondary"
          onEnter={calcHeight}
        >
          <div>
            <DropdownItem leftIcon={<FaCaretLeft />} setActiveMenu={setActiveMenu} goToMenu="main">
              Back
            </DropdownItem>
            {articles
              .filter((article) =>
                article.node.categories.some((element) => element.title === category.node.title)
              )
              .map((article) => (
                <DropdownLink key={article.node._id} linkTo={`/${article.node.slug.current}`}>
                  {article.node.shortTitle}
                </DropdownLink>
              ))}
          </div>
        </CSSTransition>
      ))}
    </div>
  );
};

export default DropdownMenu;
