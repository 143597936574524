import React from "react";
import { Link } from "gatsby";

import style from "./dropdown-item.module.scss";

function DropdownLink({ children, linkTo }) {
  return (
    <Link className={style.menuItem} to={`${linkTo}`}>
      {children}
    </Link>
  );
}
export default DropdownLink;
