import React, { useContext } from "react";

import NavItem from "../NavItem/nav-item";
import { pages, PageContext } from "../../../context/page-context";

import { FaCaretDown } from "react-icons/fa";
import DropdownMenu from "../dropdown/blog/blog-dropdown-menu";

import style from "./left-nav-desktop.module.scss";

function LeftNavDesktop({ allSanityArticle, allSanityCategory }) {
  const pageContext = useContext(PageContext);
  return (
    <div className={style.leftNav}>
      <NavItem
        highlight={pageContext.currentPage == pages.product}
        linkTo="/product"
        title="The Gin"
      />
      <NavItem
        highlight={pageContext.currentPage == pages.technical}
        linkTo="/technical"
        title="What it's About"
      />
      <NavItem dropdown title="Articles" icon={<FaCaretDown />}>
        <DropdownMenu allSanityArticle={allSanityArticle} allSanityCategory={allSanityCategory} />
      </NavItem>
      <NavItem
        highlight={pageContext.currentPage == pages.contact}
        linkTo="/contact"
        title="Contact Us"
      />
    </div>
  );
}

export default LeftNavDesktop;
