import React, { useState, FC } from "react";
import { Link } from "gatsby";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import style from "./nav-item.module.scss";

interface INavItem {
  title: string;
  linkTo?: string;
  icon?: any;
  children?: any;
  dropdown?: boolean;
  highlight?: boolean;
}

const NavItem: FC<INavItem> = ({
  title,
  linkTo = "#",
  icon,
  children,
  dropdown,
  highlight,
}: INavItem) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className={style.navItem}>
      {dropdown ? (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div>
            <a className={style.link} onClick={() => setOpen(!open)}>
              {title}
              {icon}
            </a>
            {open && children}
          </div>
        </ClickAwayListener>
      ) : (
        <Link to={linkTo} className={`${style.link} ${highlight && style.highlight}`}>
          {title}
          {icon}
        </Link>
      )}
    </div>
  );
};

export default NavItem;
