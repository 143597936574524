import React, { FC, useRef, useEffect } from "react";
import { useLocation } from "@reach/router";

// create a hook to store the previous page
const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    return () => {
      ref.current = value;
    };
  });
  return ref.current;
};

interface ITrustBoxProps {
  id: string;
  height?: string;
  width?: string;
  theme?: string;
}

const TrustBox: FC<ITrustBoxProps> = ({
  id,
  height = "24px",
  width = "100%",
  theme = "light",
}: ITrustBoxProps) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const ref = React.useRef(null);

  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    // @ts-ignore
    // window.Trustpilot.loadFromElement(ref.current, true);
    // @ts-ignore
    if (window.Trustpilot && location !== prevLocation) {
      // @ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [location, prevLocation]);
  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget" // Renamed this to className.
      data-locale="en-GB"
      data-template-id={id}
      data-businessunit-id="5f392b10d04c4d0001e9d0a3"
      data-style-height={height}
      data-style-width={width}
      data-theme={theme}
    >
      <a href="https://www.trustpilot.com/review/example.com" target="_blank" rel="noopener">
        Trustpilot
      </a>
    </div>
  );
};

export default TrustBox;
