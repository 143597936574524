import React, { FC, ReactNode } from "react";
import style from "./dropdown-item.module.scss";

interface IDropdown {
  goToMenu: string | null;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  setActiveMenu: (arg: string) => void | null;
  children: ReactNode;
}

const DropdownItem: FC<IDropdown> = ({
  goToMenu,
  leftIcon,
  rightIcon,
  setActiveMenu,
  children,
}: IDropdown) => {
  const handleClick = (): void => {
    if (goToMenu) {
      setActiveMenu(goToMenu);
    }
  };
  return (
    <a className={style.menuItem} onClick={handleClick}>
      {leftIcon && <span className={style.iconButton}>{leftIcon}</span>}
      {children}
      {rightIcon && <span className={style.iconRight}>{rightIcon}</span>}
    </a>
  );
};
export default DropdownItem;
